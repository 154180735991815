
import API_ENDPOINTS from '../apiConfig';
import { useEffect, useState, useContext } from 'react';
import axiosInstance from '../Components/axiosInstance';
import { UserContext } from '../Components/UserContext';
import styles from '../styles/Collections.module.css';
import DemoAgent from '../Components/Interactions/DemoAgent';
import 'antd/dist/reset.css';
import { Table, Card, Row, Col, Tabs, Spin, notification, Button } from 'antd';

export default function Demo() {
    const { uid } = useContext(UserContext);
    const { token } = useContext(UserContext);
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchTasks = () => {
        setLoading(true); // Start loading
        axiosInstance(token)
            .get(`${API_ENDPOINTS.demo}/tasks`)
            .then((response) => {
                const data = response.data.response.map(task => ({
                    ...task,
                    dependencies: task.dependencies.map(dep => dep.dependent_task_name).join(', '),
                }));
                setTasks(data);
            })
            .catch((error) => {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch tasks. Please try again later.',
                });
            })
            .finally(() => {
                setLoading(false); // Stop loading after fetch completes
            });
    };

    useEffect(() => {
        fetchTasks();
    }, [token]);


    const columns = [
        {
            title: 'Task Name',
            dataIndex: 'task_name',
            key: 'task_name',
            render: (text, record) => {
                return record.url ? (
                    <a href={record.url} target="_blank" rel="noopener noreferrer">
                        {text}
                    </a>
                ) : (
                    text
                );
            }
        },
        {
            title: 'Group Name',
            dataIndex: 'task_group_name',
            key: 'task_group_name',
        },
        {
            title: 'System',
            dataIndex: 'system',
            key: 'system',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
        },
        {
            title: 'Preparer Name',
            dataIndex: 'user_name',
            key: 'user_name',
        },
        {
            title: 'Work Day',
            dataIndex: 'work_day',
            key: 'work_day',
            sorter: (a, b) => a.work_day - b.work_day,
        },
        {
            title: 'Dependencies',
            dataIndex: 'dependencies',
            key: 'dependencies',
        },
        {
            title: 'Description',
            dataIndex: 'task_description',
            key: 'task_description',
        }
    ];

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        notification.success({
            message: 'Copied to Clipboard',
            description: `"${text}" has been copied.`,
            duration: 1,
        });
    };

    const questionCategories = {
        "To Do Questions": [
            "from the task manager, get me the link for the year end financial closure task",
            "using task manager, add a new task named 'eating dinner' and make up some description for it",
            "Today is work day 15, can you tell me how many today tasks are outstanding for me to catch-up with my monthly activities?",
            "Today is work day 26, can you tell me how many tasks are outstanding and what should I work on first?",
            "Of the 3 that are remaining, who are the reviewers that I have to connect with?",
            "Ok, for the first 2 tasks, I am waiting on Alice to provide me data. Can you draft an email for me to send to this person explaining I'm waiting on data to complete these tasks?",
            "Which Forecast tasks have dependencies that are not yet completed?",
            "What is the overall completion rate for the monthly tasks?",
            "Which tasks are overdue, and what are the reasons for the delays?",
            "Can you provide a summary of completed tasks versus pending tasks for a specific group or project?",
            "What monthly tasks are upcoming in the next 7 days?",
            "Who are the top performers this month based on task completion and priority?",
            "How does the workload compare among team members?",
            "What are the most common dependencies among tasks?",
        ],
        "Tabular Questions": [
            "Generate a P&L variance report.",
            "Generate a P&L variance report using latest actuals and budget.",
            `Based on latest variance, identify categories for a "Revenue" variance report (or can also call it "revenue walk"`,
            `Based on latest variance, identify categories for a "Income" variance report (or can also call it "income walk"`,
            `Based on latest variance, identify categories for a "Expense" variance report (or can also call it "expense walk"`,
        ],
        "Comment Parser Questions": [
            "start_comment_parser_actuals",
            "start_comment_parser_forecast",
            "start_comment_parser_budget",
            "Run comment parser on actuals data"
        ],
        "Report Module Questions": [
            "start_report_module",
            "run report module please"
        ]

    };


    return (
        <div className={styles.mainContainer}>
                <Row gutter={16} align="stretch" style={{height:'100%'}}>
                    <Col span={16} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card bordered hoverable style={{ flex: 1 }}>
                            <Tabs size="small">
                                <Tabs.TabPane tab={<h3>Task List</h3>} key="1">
                                    <Card>
                                        <Button type="primary" onClick={fetchTasks} style={{ marginBottom: '16px' }}>
                                            Refresh Data
                                        </Button>
                                        {loading ? (
                                            <Spin />
                                        ) : (
                                            <Table columns={columns} dataSource={tasks} rowKey="task_id" size="small"/>
                                        )}
                                    </Card>
                                </Tabs.TabPane>
                            </Tabs>
                        </Card>
                    </Col>
                   <Col span={8} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Card bordered hoverable style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <Tabs size="small">
                                <Tabs.TabPane tab={<h3>Demo Agent</h3>} key="1">
                                    <Card className="flexible-card" bordered style={{ display: 'flex', flexDirection: 'column' }}>
                                        <DemoAgent />
                                    </Card>
                                </Tabs.TabPane>
                                {Object.keys(questionCategories).map((category, index) => (
                                    <Tabs.TabPane tab={<h3>{category}</h3>} key={index + 2}>
                                        <Card style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            {questionCategories[category].map((text, questionIndex) => (
                                                <Button
                                                    key={questionIndex}
                                                    onClick={() => copyToClipboard(text)}
                                                    style={{
                                                        whiteSpace: 'normal',
                                                        wordWrap: 'break-word',
                                                        width: '100%',
                                                        height: 'auto',      // Allows the button to expand vertically
                                                        textAlign: 'left',    // Optional: Aligns text to the left for better readability
                                                        marginBottom: '10px', // Optional: Adds space between buttons
                                                    }}
                                                >
                                                    {text}
                                                </Button>
                                            ))}
                                        </Card>
                                    </Tabs.TabPane>
                                ))}
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
        </div>
    );
}
